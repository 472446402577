import React from 'react'
import { Link, graphql } from 'gatsby'

import logoChallengeNeg from '../assets/images/Logopaket_BGSC_BGSL/04_BGSC_LOGO_Paket/BGSC_LOGO_3c_farbig_negativ.svg'
import logoLigaNeg from '../assets/images/Logopaket_BGSC_BGSL/05_BGSL_LOGO_Paket/BGSL_LOGO_3c_farbig_negativ.svg'
import pinselkreisLiga from '../assets/images/pinselkreisLiga.png'
import pinselkreisChallenge from '../assets/images/pinselkreisChallenge.png'
import basketballBlack from '../assets/images/basketball.jpg'

// import Meta from '../components/Meta.js'
import Layout from '../components/Layout.js'

export default ({ location }) => {
  return (
    <Layout location={location} fullScreen={true}>
      {/* <Meta site={get(data, 'site.meta')} /> */}
      <div className="d-flex flex-column flex-md-row flex-grow-1">
        <div
          className="intro-half"
          style={{
            backgroundImage: `url(${basketballBlack})`,
            backgroundColor: 'rgba(101, 200, 192, 0.9)',
          }}
        >
          <Link to={'/challenge/'}>
            <div
              className="img-wrapper"
              style={{ backgroundImage: `url(${pinselkreisChallenge})` }}
            >
              <img src={logoChallengeNeg} alt="logoChallenge" />
              <h2 className="text-white d-none d-md-block">1./2. Klasse</h2>
              <h5 className="text-white d-md-none">1./2. Klasse</h5>
            </div>
          </Link>
        </div>
        <div
          className="intro-half"
          style={{
            backgroundImage: `url(${basketballBlack})`,
            backgroundColor: 'rgba(126, 203, 245, 0.9)',
          }}
        >
          <Link to={'/liga/'}>
            <div
              className="img-wrapper"
              style={{ backgroundImage: `url(${pinselkreisLiga})` }}
            >
              <img src={logoLigaNeg} alt="logoLiga" />
              <h2 className="text-white d-none d-md-block">3./4. Klasse</h2>
              <h5 className="text-white d-md-none">3./4. Klasse</h5>
            </div>
          </Link>
        </div>
      </div>
      <div className="footer bg-white">
        <div className="container-fluid py-1 py-md-3 px-2 px-md-5 d-flex justify-content-center justify-content-md-end">
          <h3 className="text-dark">
            <Link to="/impressum/">Impressum/Datenschutz</Link>
          </h3>
        </div>
      </div>
    </Layout>
  )
}
